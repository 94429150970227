import React from "react";
import Footer from "src/components/Footer_gr";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"//connect.facebook.net/el_GR/sdk.js#xfbml=1&version=v2.6&appId=607297119325946\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));\n"
          }}
        />
        <div id="disclosureText">
        </div>
        <div id="page" className="pageProducts">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/gr/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="nav-path-selected first has-sub-nav">
                    <a className="es_header" href="/gr/our-products/" target="_self">
                      ΤΑ ΠΡΟΪOΝΤΑ ΜΑΣ
                    </a>
                    <ul>
                      <li>
                        <a href="/gr/our-products/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/gr/our-products/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      <li>
                        <a href="/gr/our-products/sensitive/">Sensitive</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>                      
                      <li>
                        <a href="/gr/our-products/aftersun/">After Sun</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/gallery/">Gallery</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/gr/our-heritage/" target="_self">
                      Η ΜAΡΚΑ PIZ BUIN®
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/gr/know_the_sun/" target="_self">
                      ΓΝΩΡΙΣΤΕ ΤΟΝ ΗΛΙΟ
                    </a>
                  </li>
               {/*<li>
                    <a className="es_header" href="/gr/Where-Have-You-Been/" target="_self">
                      Where Have You Been 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      ΧΩΡΕΣ
                    </a>
                    <ul className="subMenu">
                      
                      <li>
                        <a href="/es/nuestros-productos/hydro-infusion/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/hydro-infusion/">PT</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/hydro-infusion/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/hydro-infusion/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="product-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt
                    src="/assets/images/2019-banner-hydro-product.jpg"
                    data-mobile="https://www.pizbuin.com/assets/images/2019-banner-hydro-product-mov.jpg"
                  />
                </div>
              </div>
            </section>
            <section id="content">
              <div className="products-page innerContent clearfix">
                <div className="products-page__intro">
                  <div className="container">
                    <h1>
                      PIZ BUIN<sup>®</sup> HYDRO INFUSION
                    </h1>
                  </div>
                </div>
                <div className="products-page__list container clearfix">
                  <div className="products-page__product clearfix">
                    <a name="sun-gel-cream-face" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-hydro-infusion-cream-50spf-50ml.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>
                        Piz Buin® Hydro Infusion Face Cream Αντηλιακη κρεμα
                        Προσωπου
                      </h3>
                      <div className="products-page__product__details__benefits">
                        <h4>ΟΦΕΛΗ</h4>
                        <p>
                          Αντηλιακό προσώπου σε μορφή gel Piz Buin® Hydro
                          Infusion Face Cream προσφέρει υψηλή προστασία.
                          Προστατεύει και ενυδατώνει την ταλαιπωρημένη από τον
                          ήλιο επιδερμίδα, προσφέροντάς της υγιή αίσθηση και
                          όψη. Η μη λιπαρή σύνθεσή του που δεν κολλάει, χαρίζει
                          απαλότητα στο δέρμα και δεν αφήνει λευκά σημάδια.
                          Ιδανικό για καθημερινή εφαρμογή με μακιγιάζ.
                        </p>
                        <p>
                          Τριπλή αντίσταση:
                          <br />
                          Περιέχει προηγμένα φίλτρα UVA/UVB
                          <br />
                          Βοηθά στην προστασία της επιδερμίδας από επιθετικούς
                          παράγοντες όπως χλώριο, θαλασσινό και αλμυρό νερό.
                          <br />
                          Είναι αδιάβροχο και ανθεκτικό στον ιδρώτα
                        </p>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>ΔΙΑΘΕΣΙΜΟ ΣΕ SPF</h4>
                        <span className="factor factor30" />
                        <span className="factor factor500" />
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              ΣΥΣΤΑΤΙΚΑ &amp; ΤΕΧΝΟΛΟΓΙΕΣ
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                <div className="textBlock bottom">
                                  <p>
                                    Aqua, Homosalate, Octocrylene, Glycerin,
                                    Butyl Methoxydibenzoylmethane,
                                    BisEthylhexyloxyphenol Methoxyphenyl
                                    Triazine, Styrene/Acrylates Copolymer,
                                    Alcohol Denat., Caprylyl Methicone,
                                    Diisopropyl Adipate, Silica, Dicaprylyl
                                    Carbonate, Glycine Soja Oil, Dimethicone,
                                    Pentylene Glycol, Linseed Acid, Hydrogenated
                                    Palm Glycerides, Glyceryl Stearate, Menthyl
                                    Lactate, Acrylates/Dimethicone Copolymer,
                                    Acrylates Copolymer, Trideceth-6, PEG-8
                                    Laurate, Potassium Cetyl Phosphate, Sodium
                                    Dodecylbenzenesulfonate, Acrylates/C10-30
                                    AlkylAcrylateCrosspolymer,Polyurethane-62,
                                    Disodium EDTA, Sodium Hydroxide,
                                    Hydroxyacetophenone, Tocopherol, Tocopheryl
                                    Acetate, Phenoxyethanol, Parfum.[PR-018945]
                                  </p>
                                  <div> </div>
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="sun-gel-cream" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-hydro-infusion-sun-gel-cream-50spf-150ml.png" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>
                        Piz Buin® HYDRO INFUSION SUN GEL CREAM ΚΡΕΜΑ ΣΩΜΑΤΟΣ
                      </h3>
                      <div className="products-page__product__details__benefits">
                        <h4>ΟΦΕΛΗ</h4>
                        <p>
                          Το αντηλιακό σώματος Piz Buin® Hydro Infusion Sun Gel
                          Cream με υψηλή προστασία αναζωογονεί άμεσα,
                          προστατεύοντας και ενυδατώνοντας την ταλαιπωρημένη από
                          τον ήλιο επιδερμίδα, προσφέροντάς της υγιή αίσθηση και
                          όψη. Προστατεύει από τις αρνητικές επιπτώσεις του
                          ήλιου και της μακροχρόνιας έκθεσης στις ακτίνες UVA
                          στην επιδερμίδα και από την προκαλούμενη από τον ήλιο
                          πρόωρη γήρανση του δέρματος. Η μη λιπαρή σύνθεσή του
                          που δεν κολλάει, απορροφάται άμεσα και δεν αφήνει
                          λευκά σημάδια
                        </p>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>ΔΙΑΘΕΣΙΜΟ ΣΕ SPF</h4>
                        <span className="factor factor30" />                        
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              ΣΥΣΤΑΤΙΚΑ &amp; ΤΕΧΝΟΛΟΓΙΕΣ{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                <div className="textBlock bottom">
                                  <p>
                                    Aqua, Homosalate, Octocrylene, Glycerin,
                                    Butyl Methoxydibenzoylmethane,
                                    BisEthylhexyloxyphenol Methoxyphenyl
                                    Triazine, Styrene/Acrylates Copolymer,
                                    Alcohol Denat., Caprylyl Methicone,
                                    Diisopropyl Adipate, Silica, Dicaprylyl
                                    Carbonate, Glycine Soja Oil, Dimethicone,
                                    Pentylene Glycol, Linseed Acid, Hydrogenated
                                    Palm Glycerides, Glyceryl Stearate, Menthyl
                                    Lactate, Acrylates/Dimethicone Copolymer,
                                    Acrylates Copolymer, Trideceth-6, PEG-8
                                    Laurate, Potassium Cetyl Phosphate, Sodium
                                    Dodecylbenzenesulfonate, Acrylates/C10-30
                                    AlkylAcrylateCrosspolymer,Polyurethane-62,
                                    Disodium EDTA, Sodium Hydroxide,
                                    Hydroxyacetophenone, Tocopherol, Tocopheryl
                                    Acetate, Phenoxyethanol, Parfum.[PR-018945]
                                  </p>
                                  <div> </div>
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <nav className="platform-links">
              <div className="container">
                <ul>
                  <li>
                    <a
                      className="first"
                      href="/gr/our-products/moisturising/"
                      target="_self"
                    >
                      Moisturising
                    </a>
                  </li>
                  <li>
                    <a
                      className="platform-links__active nav-path-selected"
                      href="/gr/our-products/hydro-infusion/"
                      target="_self"
                    >
                      Hydro Infusion
                    </a>
                  </li>
                  <li>
                    <a href="/gr/our-products/sensitive/" target="_self">
                      Sensitive
                    </a>
                  </li>
                  <li>
                    <a href="/gr/our-products/tan_protect/" target="_self">
                      Tan &amp; Protect
                    </a>
                  </li>
                  <li>
                    <a href="/gr/our-products/instantglow/" target="_self">
                      Instant Glow
                    </a>
                  </li>
                  <li>
                    <a href="/gr/our-products/aftersun/" target="_self">
                      After Sun
                    </a>
                  </li>
                  <li>
                    <a href="/gr/our-products/gallery/" target="_self">
                      Gallery
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function(){\n$('.products-page__product__details__benefits__more').on('click', function(){\nvar box = $(this).siblings('.products-page__product__details__benefits__hidden');\nif(box.is(':visible')){\nbox.slideUp();\n$(this).text('Διαβάστε περισσότερα');\n} else {\nbox.slideDown();\n$(this).text('Διαβάστε λιγότερα');\n}\n});\n});\n"
          }}
        />
        <div className="clear">
          <br />
        </div>
      </div>
    );
  }
}

export default Page;
